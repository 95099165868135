import * as React from 'react';

import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import Link from './Link';

const useStyles = makeStyles((theme) => ({
    pagenation: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    prev: {
        padding: theme.spacing(1),
        flex: 1,
        textAlign: `left`
    },
    category: {
        padding: theme.spacing(1),
        flex: 1,
        textAlign: `center`
    },
    next: {
        padding: theme.spacing(1),
        flex: 1,
        textAlign: `right`
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    }
}));

const Pagenation = ({ categoryTitle, categorySlug, previous, next }) => {
    const classes = useStyles()
    const path = categorySlug === null ? `/blog` : `/${categorySlug}`

    return (
        <Toolbar
            component="nav"
            variant="dense"
            className={classes.pagenation}
        >
            <Typography className={classes.prev}>
                {previous
                    ? <Link className={classes.wrapIcon} to={`${path}/${previous.slug || previous.id}`}>
                        <SkipPreviousIcon />{previous.title}
                    </Link>
                    : <SkipPreviousIcon color="disabled" />
                }

            </Typography>
            {categorySlug &&
                <Typography className={classes.category}>
                    <Link to={`/${categorySlug}`}>{categoryTitle}</Link>
                </Typography>
            }
            <Typography className={classes.next}>
                {next
                    ? <Link className={classes.wrapIcon} to={`${path}/${next.slug || next.id}`}>
                        {next.title}<SkipNextIcon />
                    </Link>
                    : <SkipNextIcon color="disabled" />
                }
            </Typography>
        </Toolbar>)
}

Pagenation.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
    ).isRequired,
    previous: PropTypes.object,
    next: PropTypes.object,
};

export default Pagenation;
